import React, { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import NavDashboard from "../components/layout/NavDashboard";
import Reveal from "../components/motion/Reveal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { httpReauest } from "../utils/httprequest";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL, CDN_BASE_URL } from "../config";
import moment from "moment";
import { FaCheck, FaPlus } from "react-icons/fa6";

const DashboardPremium = () => {
  const [data, setData] = useState();
  const [editing, setediting] = useState(false);
  const [img, setImg] = useState();
  const [previewImg, setPreviewImg] = useState();

  const navigate = useNavigate();
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (!user?.token) {
      navigate("/signup");
    }
    getData();
  }, []);

  async function getData() {
    const user = JSON.parse(localStorage.getItem("user"));
    await httpReauest(
      "GET",
      "/user/" + user?._id,
      {},
      { "x-access-token": user?.token }
    )
      .then((newData) => {
        setData(newData.data.data);
      })
      .catch(() => {
        localStorage.removeItem("user");

        navigate("/signup");
      });
  }

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      address: Yup.string().required("الزامی است"),
      postalCode: Yup.string().required("الزامی است"),
    });
  };

  const formik = useFormik({
    initialValues: { address: "", postalCode: "", desc: "" },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      const user = JSON.parse(localStorage.getItem("user"));
      await httpReauest("POST", "/user/addAddress", values, {
        "x-access-token": user?.token,
      })
        .then((data) => {
          toast.success("اطلاعات با موفقیت ثبت شد");
          formik.resetForm();
          window.location.reload();
        })
        .catch((data) => {
          toast.error(data?.response?.data?.message);
          formik.resetForm();
        });
    },
  });

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (!img) {
      return toast.error("فیش واریزی را آپلود کنید");
    }
    const user = JSON.parse(localStorage.getItem("user"));
    const formdata = new FormData();
    formdata.append("userId", user._id);
    formdata.append("payment", img);

    await fetch(BASE_URL + "/user/premium", {
      method: "POST",
      body: formdata,
      headers: { "x-access-token": user.token },
    }).then((res) => {
      toast.success("موفقیت آمیز");
      getData();
    });
  }

  return (
    <div>
      <Header />
      <div class="app-content bg-[#eee]">
        <div class="u-s-p-y-60">
          <div class="section__content">
            <div class="dash">
              <div class="container">
                <div class="row">
                  <NavDashboard orderCount={data?.orders?.length} />
                  <div class="col-lg-9 col-md-12">
                    <Reveal>
                      <div class="dash__box dash__box--shadow dash__box--radius dash__box--bg-white u-s-m-b-30">
                        {data?.premium ? (
                          <>
                            {" "}
                            <div class="dash__pad-2">
                              <h1 class="dash__h1 u-s-m-b-15">خرید اشتراک</h1>
                              <span class="dash__text">
                                تبریک شما در حال حاضر اشتراک دارید میتوانید از
                                محصولات سایت استفاده کنید
                              </span>
                              <span className="mx-auto">
                                <FaCheck
                                  color="green"
                                  className="mx-auto"
                                  size={45}
                                />
                              </span>
                            </div>
                          </>
                        ) : data?.in_proggress ? (
                          <>
                            <div class="dash__pad-2">
                              <h1 class="dash__h1 u-s-m-b-15">خرید اشتراک</h1>
                              <span className="mx-auto p-2 ">
                                چک پرداختی شما در دست بررسی است
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            {" "}
                            <div class="dash__pad-2">
                              <h1 class="dash__h1 u-s-m-b-15">خرید اشتراک</h1>
                              <span class="dash__text">
                                با داشتن اشتراک میتوانید همواره از محصولات سایت
                                به صورت رایگان بهره مند باشید و استفاده کنید{" "}
                                <br />
                                هزینه : 500,000 تومان
                              </span>
                              <form
                                onSubmit={handleSubmit}
                                className="flex flex-col gap-2 items-center justify-center mt-3 max-w-[320px] mx-auto"
                              >
                                <label
                                  htmlFor="img"
                                  className="relative w-full  border-dashed border-2  rounded-[10px] p-3 h-[160px] cursor-pointer border-[#444] "
                                >
                                  {previewImg ? (
                                    <img
                                      src={previewImg}
                                      className="w-full h-full object-contain"
                                      alt=""
                                    />
                                  ) : (
                                    <FaPlus
                                      className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                                      size={30}
                                    />
                                  )}
                                </label>
                                <input
                                  dir="ltr"
                                  disabled
                                  class="shadow border rounded p-2 mt-3 w-full"
                                  value="5022-2910-1917-7474"
                                  data-listener-added_1639567b="true"
                                />
                                <span className="text-right w-full">
                                  مهیار بابازاده
                                </span>
                                <button className="p-2 rounded btn--e-brand">
                                  ارسال
                                </button>
                                <input
                                  onChange={(e) => {
                                    handleImg(e);
                                  }}
                                  type="file"
                                  className="hidden"
                                  id="img"
                                  accept="image/png, image/jpg, image/jpeg"
                                />
                              </form>
                            </div>
                          </>
                        )}
                      </div>
                    </Reveal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DashboardPremium;
