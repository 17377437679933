import React, { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Reveal from "../components/motion/Reveal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { httpReauest } from "../utils/httprequest";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import SignupForm from "../components/common/SignupForm";

const Signup = () => {
  
  return (
    <>
      <Header />
      <div style={{ background: "#eee" }} class="app-content">
        <div class="u-s-p-y-60">
          <div class="section__intro u-s-m-b-60">
            <div class="container">
              <div class="row row--center">
                <Reveal>
                  <div class="col-lg-12">
                    <div class="section__text-wrap">
                      <h1 class="section__heading u-c-secondary">ثبت نام</h1>
                    </div>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>

          <div class="section__content">
            <div class="container">
              <SignupForm />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Signup;
