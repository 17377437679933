import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Reveal from "../motion/Reveal";
import { SrviceInfo } from "../../pages/ServiceDetails";

const Header = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  return (
    <header class="header--style-1">
      <Reveal head>
        <nav class="primary-nav primary-nav-wrapper--border">
          <div class="container">
            <div class="primary-nav">
              <Link class="main-logo  " to="/">
                <img className="w-[70px]" src="/logo.png" alt="" />
              </Link>

              <div class="ah-lg-mode">
                <span class="ah-close">✕ Close</span>

                <ul class="ah-list ah-list--design2 ah-list--link-color-secondary">
                  <li>
                    <Link to="/">خانه</Link>
                  </li>
                  <li class="has-dropdown">
                    <a>
                      خدمات<i class="fa-solid fa-angle-down u-s-m-r-6"></i>
                    </a>

                    <span class="js-menu-toggle"></span>
                    <ul style={{ width: "200px" }}>
                      {SrviceInfo.map((e) => {
                        return (
                          <li>
                            <Link to={"/service/" + e.id}>{e.title}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>

                  <li class="has-dropdown">
                    <a>
                      روادید<i class="fa-solid fa-angle-down u-s-m-r-6"></i>
                    </a>

                    <span class="js-menu-toggle"></span>
                    <ul style={{ width: "200px" }}>
                      <li>
                        <Link to="/event">سمینار</Link>
                      </li>
                      <li>
                        <Link to="/event">تور</Link>
                      </li>
                      <li>
                        <Link to="/event">کارگاه آموزشی</Link>
                      </li>
                      <li>
                        <Link to="/event">بوت کمپ</Link>
                      </li>
                    </ul>
                  </li>
                  <li class="has-dropdown">
                    <Link to="/products">
                      محصولات<i class="fa-solid fa-angle-down u-s-m-r-6"></i>
                    </Link>

                    <span class="js-menu-toggle"></span>
                    <ul style={{ width: "200px" }}>
                      <li>
                        <Link to="/products/courses">دوره های آموزشی</Link>
                      </li>
                      <li>
                        <Link to="/products/pdf-courses">
                          دوره های آموزشی PDF
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/about">درباره ما</Link>
                  </li>
                  <li>
                    <Link to="/service/branding">ارتباط با ما</Link>
                  </li>
                  <li>
                    <Link to="/ُupport">پشتیبانی</Link>
                  </li>
                </ul>
              </div>

              <div class="menu-init" id="navigation">
                <button
                  class="btn btn--icon text-black toggle-button toggle-button--secondary fa-solid fa-align-justify"
                  type="button"
                  onClick={() => setShow(true)}
                ></button>

                <div
                  className={`${
                    !show && "left-[-100%]"
                  } fixed left-0 top-0 h-full w-[250px] bg-white z-10 duration-500`}
                >
                  <div className="w-full border-b p-1">
                    <span onClick={() => setShow(false)} class="ah-close">
                      ✕
                    </span>
                  </div>
                  <div className="w-full">
                    <ul class="ah-list ah-list--design2 ah-list--link-color-secondary w-full">
                      <li>
                        <Link to="/">خانه</Link>
                      </li>
                      <li>
                        <Link to="/signup">ثبت نام</Link>
                      </li>
                      <li>
                        <Link to="/signin">ورود</Link>
                      </li>
                      <li class="has-dropdown">
                        <a>
                          خدمات<i class="fa-solid fa-angle-down u-s-m-r-6"></i>
                        </a>
                        <span
                          onClick={() => setShow2(!show2)}
                          class="js-menu-toggle"
                        ></span>
                        {show2 && (
                          <ul style={{ width: "200px" }}>
                            {SrviceInfo.map((e) => {
                              return (
                                <li>
                                  <Link to={"/service/" + e.id}>{e.title}</Link>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </li>

                      <li class="has-dropdown">
                        <a>
                          روادید<i class="fa-solid fa-angle-down u-s-m-r-6"></i>
                        </a>

                        <span
                          class="js-menu-toggle"
                          onClick={() => setShow3(!show3)}
                        ></span>
                        {show3 && (
                          <ul style={{ width: "200px" }}>
                            <li>
                              <Link to="/event">سمینار</Link>
                            </li>
                            <li>
                              <Link to="/event">تور</Link>
                            </li>
                            <li>
                              <Link to="/event">کارگاه آموزشی</Link>
                            </li>
                            <li>
                              <Link to="/event">بوت کمپ</Link>
                            </li>
                          </ul>
                        )}
                      </li>
                      <li class="has-dropdown">
                        <a>
                          محصولات
                          <i class="fa-solid fa-angle-down u-s-m-r-6"></i>
                        </a>
                        <span
                          onClick={() => setShow4(!show4)}
                          class="js-menu-toggle"
                        ></span>
                        {show4 && (
                          <ul style={{ width: "200px" }}>
                            <li>
                              <Link to="/products">دوره های آموزشی</Link>
                            </li>
                            <li>
                              <Link to="/products">دوره های آموزشی PDF</Link>
                            </li>
                          </ul>
                        )}
                      </li>
                      <li>
                        <Link to="/service/branding">درباره ما</Link>
                      </li>
                      <li>
                        <Link to="/service/branding">ارتباط با ما</Link>
                      </li>
                      <li>
                        <Link to="/suppourt">پشتیبانی</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="ah-lg-mode">
                  <span class="ah-close">✕ Close</span>

                  <ul class="ah-list ah-list--design1 ah-list--link-color-secondary">
                    <li
                      class="has-dropdown"
                      data-tooltip="tooltip"
                      data-placement="left"
                      title="Account"
                    >
                      <span class="js-menu-toggle"></span>
                    </li>
                    <li
                      class="has-dropdown"
                      data-tooltip="tooltip"
                      data-placement="left"
                      title="Account"
                    >
                      <a>
                        <i class="fa-solid fa-user-circle"></i>
                      </a>

                      <span class="js-menu-toggle"></span>
                      <ul style={{ width: "120px" }}>
                        <li>
                          <Link to="/dashboard">
                            <i class="fa-solid fa-user-circle u-s-m-l-6"></i>

                            <span>پروفایل</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/signup">
                            <i class="fa-solid fa-user-plus u-s-m-l-6"></i>

                            <span>ثبت نام</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/signin">
                            <i class="fa-solid fa-lock u-s-m-l-6"></i>

                            <span>ورود</span>
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li
                      data-tooltip="tooltip"
                      data-placement="left"
                      title="Contact"
                    >
                      <a href="tel:+0900901904">
                        <i class="fa-solid fa-phone-volume"></i>
                      </a>
                    </li>
                    <li
                      data-tooltip="tooltip"
                      data-placement="left"
                      title="Mail"
                    >
                      <a href="mailto:contact@domain.com">
                        <i class="fa-solid fa-envelope"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </Reveal>
    </header>
  );
};

export default Header;
