import React, { useEffect, useRef, useState } from "react";
import Reveal from "../motion/Reveal";

const ColorSwitcher = () => {
  const [open, setOpen] = useState(false);
  const [color1, setcolor1] = useState(true);
  const [color2, setcolor2] = useState(false);
  const [color3, setcolor3] = useState(false);
  const [color4, setcolor4] = useState(false);
  const [color5, setcolor5] = useState(false);
  const [color6, setcolor6] = useState(false);
  const [color7, setcolor7] = useState(false);
  const [color8, setcolor8] = useState(false);
  const [color9, setcolor9] = useState(false);
  const [color10, setcolor10] = useState(false);

  function handleOpen(params) {
    setOpen(!open);
  }

  function changeColor2(event) {
    setcolor1(false);
    setcolor2(false);
    setcolor3(false);
    setcolor4(false);
    setcolor5(false);
    setcolor6(false);
    setcolor7(false);
    setcolor8(false);
    setcolor9(false);
    setcolor10(false);
    setOpen(false);
    if (event.target.title == "color1") {
      setcolor1(true);
    }
    if (event.target.title == "color2") {
      setcolor2(true);
    }
    if (event.target.title == "color3") {
      setcolor3(true);
    }
    if (event.target.title == "color4") {
      setcolor4(true);
    }
    if (event.target.title == "color5") {
      setcolor5(true);
    }
    if (event.target.title == "color6") {
      setcolor6(true);
    }
    if (event.target.title == "color7") {
      setcolor7(true);
    }
    if (event.target.title == "color8") {
      setcolor8(true);
    }
    if (event.target.title == "color9") {
      setcolor9(true);
    }
    if (event.target.title == "color10") {
      setcolor10(true);
    }
    localStorage.setItem("color", event.target.style.backgroundColor);
    document.documentElement.style.setProperty(
      "--brand",
      event.target.style.backgroundColor
    );
  }

  useEffect(() => {
    const color = localStorage.getItem("color");
    console.log(color);
    if (color) {
      document.documentElement.style.setProperty("--brand", color);
    } else {
      setOpen(true);
    }
  }, []);

  return (
    <div>
      <div class={`switcher ${open && "js-switcher-open"}`}>
        <div onClick={handleOpen} class="switcher__toggle">
          <span class="fa-solid fa-cog"></span>
        </div>
        <Reveal width={"fit-content"}>
          <div dir="rtl" class="switcher__wrap">
            <h3 class="switcher__h3 u-s-m-b-6">عوض کننده رنگ</h3>

            <span class="switcher__text ">
              سایت رو به رنگی که دوست داری تغییر بده <br /> رویه یکی از رنگ ها
              کلیک کن
            </span>
            <div class="switcher__color">
              <div
                class={`color ${color1 && "js-color-select"}`}
                style={{ backgroundColor: "#fb7c56" }}
                title="color1"
                onClick={changeColor2}
              ></div>
              <div
                class={`color ${color2 && "js-color-select"}`}
                title="color2"
                style={{ backgroundColor: "#ff003b" }}
                onClick={changeColor2}
              ></div>
              <div
                class={`color ${color3 && "js-color-select"}`}
                title="color3"
                style={{ backgroundColor: "#003bff" }}
                onClick={changeColor2}
              ></div>
              <div
                class={`color ${color4 && "js-color-select"}`}
                title="color4"
                style={{ backgroundColor: "#156844" }}
                onClick={changeColor2}
              ></div>
              <div
                class={`color ${color5 && "js-color-select"}`}
                title="color5"
                style={{ backgroundColor: "#c500ff" }}
                onClick={changeColor2}
              ></div>
              <div
                class={`color ${color6 && "js-color-select"}`}
                title="color6"
                style={{ backgroundColor: "#382E2E" }}
                onClick={changeColor2}
              ></div>
              <div
                class={`color ${color7 && "js-color-select"}`}
                title="color7"
                style={{ backgroundColor: "#116466" }}
                onClick={changeColor2}
              ></div>
              <div
                class={`color ${color8 && "js-color-select"}`}
                title="color8"
                style={{ backgroundColor: "#1B469A" }}
                onClick={changeColor2}
              ></div>
              <div
                class={`color ${color9 && "js-color-select"}`}
                title="color9"
                style={{ backgroundColor: "#0D122C" }}
                onClick={changeColor2}
              ></div>
              <div
                class={`color ${color10 && "js-color-select"}`}
                title="color10"
                style={{ backgroundColor: "#ff1500" }}
                onClick={changeColor2}
              ></div>
            </div>
          </div>
        </Reveal>
      </div>
    </div>
  );
};

export default ColorSwitcher;
