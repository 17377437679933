import React, { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import NavDashboard from "../components/layout/NavDashboard";
import Reveal from "../components/motion/Reveal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { httpReauest } from "../utils/httprequest";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { CDN_BASE_URL } from "../config";
import moment from "moment";

const Dashboard = () => {
  const [data, setData] = useState();
  const [editing, setediting] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (!user?.token) {
      navigate("/signup");
    }
    getData();
  }, []);

  async function getData() {
    const user = JSON.parse(localStorage.getItem("user"));
    await httpReauest(
      "GET",
      "/user/" + user?._id,
      {},
      { "x-access-token": user?.token }
    )
      .then((newData) => {
        setData(newData.data.data);
      })
      .catch(() => {
        localStorage.removeItem("user");

        navigate("/signup");
      });
  }

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      address: Yup.string().required("الزامی است"),
      postalCode: Yup.string().required("الزامی است"),
    });
  };

  const formik = useFormik({
    initialValues: { address: "", postalCode: "", desc: "" },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      const user = JSON.parse(localStorage.getItem("user"));
      await httpReauest("POST", "/user/addAddress", values, {
        "x-access-token": user?.token,
      })
        .then((data) => {
          toast.success("اطلاعات با موفقیت ثبت شد");
          formik.resetForm();
          window.location.reload();
        })
        .catch((data) => {
          toast.error(data?.response?.data?.message);
          formik.resetForm();
        });
    },
  });
  return (
    <div>
      <Header />
      <div class="app-content bg-[#eee]">
        <div class="u-s-p-y-60">
          <div class="section__content">
            <div class="dash">
              <div class="container">
                <div class="row">
                  <NavDashboard orderCount={data?.orders?.length} />
                  <div class="col-lg-9 col-md-12">
                    <Reveal>
                      <div class="dash__box dash__box--shadow dash__box--radius dash__box--bg-white u-s-m-b-30">
                        <div class="dash__pad-2">
                          <h1 class="dash__h1 u-s-m-b-25">اطلاعات من</h1>
                          <div class="row">
                            <div class="col-lg-4 u-s-m-b-30  ">
                              <h2 class="dash__h2 u-s-m-b-8">نام</h2>

                              <span class="dash__text">{data?.f_name}</span>
                            </div>
                            <div class="col-lg-4 u-s-m-b-30">
                              <h2 class="dash__h2 u-s-m-b-8">نام خانوادگی</h2>

                              <span class="dash__text">{data?.l_name}</span>
                            </div>
                            <div class="col-lg-4 u-s-m-b-30">
                              <h2 class="dash__h2 u-s-m-b-8">شماره تماس</h2>

                              <span class="dash__text">{data?.phone}</span>
                            </div>
                            {data?.premium ? (
                              <div class="col-12 flex  flex-wrap gap-2 u-s-m-b-30">
                                <h2 class="dash__h2 u-s-m-b-8">
                                  شما دارای اشتراک هستید
                                </h2>
                              </div>
                            ) : (
                              <div class="col-12 flex  flex-wrap gap-2 u-s-m-b-30">
                                <h2 class="dash__h2 u-s-m-b-8">
                                  با داشتن اشتراک میتوانید به همه محصولات سایت
                                  دسترسی داشته باشید
                                </h2>
                                <Link
                                  to={"/dashboard/premium"}
                                  className="p-2 rounded btn--e-brand"
                                >
                                  خرید اشتراک
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Reveal>
                    <Reveal>
                      <div class="dash__box dash__box--shadow dash__box--bg-white dash__box--radius">
                        <h2 class="dash__h2 u-s-p-xy-20">روادید ثبت نام شده</h2>
                        <div class="dash__table-wrap gl-scroll">
                          <table class="dash__table">
                            <thead>
                              <tr>
                                <th>اسم رویداد</th>
                                <th>در تاریخ</th>
                                <th>وضعیت</th>
                                <th>قیمت</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.orders?.map((order) => {
                                return (
                                  <tr>
                                    <td>{order?._id}</td>
                                    <td>
                                      {moment(order?.createdAt).format(
                                        "YYYY-MM-DD"
                                      )}
                                    </td>
                                    <td>{order?.status}</td>

                                    <td>
                                      <div class="dash__table-total">
                                        <span>
                                          {order?.price?.toLocaleString()}ريال
                                        </span>
                                        <div class="dash__link dash__link--brand">
                                          <Link to={"/order/" + order?._id}>
                                            مدیریت
                                          </Link>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Reveal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;
