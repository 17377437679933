import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import ProductSlider from "../components/common/ProductSlider";
import Reveal from "../components/motion/Reveal";
import ProductCard from "../components/common/ProductCard";
import { httpReauest } from "../utils/httprequest";
import { Link, useParams } from "react-router-dom";
import RateToStar from "../utils/rateToStart";
import { toast } from "react-toastify";
import { CDN_BASE_URL } from "../config";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const ProductDetails = () => {
  const [reviewShow, setReviewShow] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [content, setContent] = useState();
  const [user, setuser] = useState();
  const [rate, setrate] = useState();

  const [currentTrack, setTrackIndex] = useState(0);
  const handleClickNext = () => {
    console.log("click next");
    setTrackIndex((currentTrack) =>
      currentTrack < data.files.length - 1 ? currentTrack + 1 : 0
    );
  };

  const handleEnd = () => {
    console.log("end");
    setTrackIndex((currentTrack) =>
      currentTrack < data.files.length - 1 ? currentTrack + 1 : 0
    );
  };

  const { id } = useParams();

  const [data, setData] = useState();
  const [data2, setData2] = useState();
  useEffect(() => {
    getData();
  }, [id]);

  async function getUser(_id, token) {
    await httpReauest(
      "GET",
      "/user/" + _id,
      {},
      { "x-access-token": token }
    ).then((res) => {
      setuser(res.data.data);
    });
  }

  async function getData() {
    const newData = await httpReauest("GET", "/product/" + id, {}, {});

    setData(newData.data.data);

    const newData2 = await httpReauest("GET", "/product", {}, {});

    setData2(newData2.data.data);

    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      setIsLogin(true);
      getUser(user._id, user.token);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const user = JSON.parse(localStorage.getItem("user"));

    if (!rate || !content) {
      toast.error("امتیاز و متن هر دو الزامی است برای ثبت نظر");
    }

    await httpReauest(
      "POST",
      "/product/addReview/" + id,
      { content, rate },
      { "x-access-token": user.token }
    )
      .then((e) => {
        toast.success("نظر شما با موفقیت ثبت شد");
        setContent("");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }
  return (
    <div>
      <Header />
      <div style={{ background: "#eee" }} class="app-content px-4">
        <div class="u-s-p-t-60">
          <div class="container bg-white rounded-[25px] p-4">
            <div class="row items-center">
              <div class="col-lg-5">
                <div class="pd u-s-m-b-30">
                  <ProductSlider data={data?.images} />
                </div>
              </div>
              <div class="col-lg-7">
                <div class="pd-detail">
                  <Reveal width={"fit-content"}>
                    <div>
                      <span class="pd-detail__name">{data?.name}</span>
                    </div>
                  </Reveal>
                  <div class="u-s-m-b-15 u-s-m-t-5">
                    <Reveal width={"fit-content"}>
                      <div class="pd-detail__rating gl-rating-style">
                        {data?.rate ? (
                          <>
                            <RateToStar number={data?.rate} />
                            <span class="pd-detail__review u-s-m-l-4">
                              <a data-click-scroll="#view-review">
                                {data?.reviews?.length} نظر
                              </a>
                            </span>
                          </>
                        ) : (
                          <a data-click-scroll="#view-review">
                            هنوز نظر و امتیازی ثبت نشده
                          </a>
                        )}
                      </div>
                    </Reveal>
                  </div>

                  <div class="u-s-m-b-25">
                    <Reveal width={"fit-content"}>
                      <span class="pd-detail__preview-desc">{data?.desc}</span>
                    </Reveal>
                  </div>
                  <div class="u-s-m-b-15">
                    <form class="pd-detail__form">
                      <Reveal>
                        <div class="pd-detail-inline-2">
                          <div class="u-s-m-y-10 w-full">
                            {user ? (
                              <>
                                {user.premium ? (
                                  <>
                                    {!data?.voice ? (
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          window.location.assign(
                                            CDN_BASE_URL + data?.file
                                          );
                                        }}
                                        class="btn btn--e-brand-b-2 block w-fit mt-3"
                                      >
                                        دانلود
                                      </button>
                                    ) : (
                                      <div
                                        className="w-full mt-3 fixed bottom-0 left-0 z-40 lg:relative bg-white "
                                        dir="ltr"
                                      >
                                        <div className="flex justify-between mb-2 items-center pt-2 px-2">
                                          <Link
                                            download
                                            to={
                                              CDN_BASE_URL +
                                              data.files[currentTrack]
                                            }
                                            className="bg-[--brand] text-white p-2 rounded text-[12px]"
                                          >
                                            دانلود
                                          </Link>
                                          <p className="text-right font-bold text-black">
                                            قسمت {currentTrack + 1} از{" "}
                                            {data?.files.length}
                                          </p>
                                        </div>

                                        <AudioPlayer
                                          src={
                                            CDN_BASE_URL +
                                            data.files[currentTrack]
                                          }
                                          onPlay={(e) => console.log("onPlay")}
                                          showSkipControls
                                          customAdditionalControls={[]}
                                          onClickNext={handleClickNext}
                                          onEnded={handleEnd}
                                          // other props here
                                        />
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <div className="text-black">
                                      برای استفاده از محصولات باید اشتراک داشته
                                      باشید
                                    </div>
                                    <Link
                                      to={"/dashboard/premium"}
                                      class="btn btn--e-brand-b-2 block w-fit mt-3"
                                    >
                                      اشتراک
                                    </Link>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <div className="text-black">
                                  برای خرید محصول باید ثبت نام کنید و اشتراک
                                  تهیه کنید
                                </div>
                                <Link
                                  to={"/signup"}
                                  class="btn btn--e-brand-b-2 block w-fit mt-3"
                                >
                                  ثبت نام
                                </Link>
                              </>
                            )}
                          </div>
                        </div>
                      </Reveal>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="u-s-m-y-60">
          <div class="container bg-white rounded-[25px] p-4">
            <div class="row">
              <div class="col-lg-12">
                <div class="pd-tab">
                  <div class="u-s-m-b-30">
                    <ul class="nav pd-tab__list">
                      <li class="nav-item">
                        <a
                          class={`nav-link ${!reviewShow && "active"}`}
                          data-toggle="tab"
                          onClick={() => setReviewShow(false)}
                        >
                          مشخصات
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class={`nav-link ${reviewShow && "active"}`}
                          id="view-review"
                          data-toggle="tab"
                          onClick={() => setReviewShow(true)}
                        >
                          نظرات
                          <span>({data?.reviews?.length})</span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div class="tab-content">
                    <div
                      class={`tab-pane ${!reviewShow && "fade show active"}`}
                      id="pd-desc"
                    >
                      <div class="pd-tab__desc">
                        <div class="u-s-m-b-30">
                          <Reveal width={"fit-content"}>
                            <p>{data?.desc}</p>
                          </Reveal>
                        </div>
                      </div>
                    </div>
                    <div class={`tab-pane ${reviewShow && "fade show active"}`}>
                      <div class="pd-tab__rev">
                        <div class="u-s-m-b-30">
                          <div class="pd-tab__rev-score">
                            <div class="u-s-m-b-8">
                              <h2>
                                {data?.reviews?.length} نظر -{" "}
                                {data?.rate ? data.rate : "0"} (امتیاز)
                              </h2>
                            </div>
                            <div class="gl-rating-style-2 u-s-m-b-8">
                              <RateToStar number={data?.rate} />
                            </div>
                            <div class="u-s-m-b-8">
                              <h4>نظر شما برای ما خیلی مهم است</h4>
                            </div>
                          </div>
                        </div>
                        <div class="u-s-m-b-30">
                          <form class="pd-tab__rev-f1">
                            <div class="rev-f1__group">
                              <div class="u-s-m-b-15">
                                <h2>نظرات</h2>
                              </div>
                            </div>
                            <div class="rev-f1__review">
                              {data?.reviews?.map((review) => {
                                return (
                                  <div class="review-o u-s-m-b-15">
                                    <div class="review-o__info u-s-m-b-8">
                                      <span class="review-o__name">
                                        {review?.userId?.f_name}{" "}
                                        {review?.userId?.l_name}
                                      </span>
                                    </div>
                                    <div class="review-o__rating gl-rating-style u-s-m-b-8 flex">
                                      <RateToStar number={review?.rate} />

                                      <span>({review?.rate})</span>
                                    </div>
                                    <p class="review-o__text">
                                      {review?.content}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          </form>
                        </div>
                        {isLogin ? (
                          <div class="u-s-m-b-30">
                            <form class="pd-tab__rev-f2">
                              <h2 class="u-s-m-b-25">نظری اضافه کنید</h2>

                              <div class="u-s-m-b-30">
                                <div class="rev-f2__table-wrap gl-scroll">
                                  <table class="rev-f2__table">
                                    <thead>
                                      <tr>
                                        <th>
                                          <div class="gl-rating-style-2">
                                            <i class="fa-solid fa-star"></i>

                                            <span>(1)</span>
                                          </div>
                                        </th>

                                        <th>
                                          <div class="gl-rating-style-2">
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>

                                            <span>(2)</span>
                                          </div>
                                        </th>

                                        <th>
                                          <div class="gl-rating-style-2">
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>

                                            <span>(3)</span>
                                          </div>
                                        </th>

                                        <th>
                                          <div class="gl-rating-style-2">
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>

                                            <span>(4)</span>
                                          </div>
                                        </th>

                                        <th>
                                          <div class="gl-rating-style-2">
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>

                                            <span>(5)</span>
                                          </div>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div class="radio-box">
                                            <input
                                              type="radio"
                                              id="star-1"
                                              name="rating"
                                              onClick={() => setrate(1)}
                                            />
                                            <div class="radio-box__state radio-box__state--primary">
                                              <label
                                                class="radio-box__label"
                                                for="star-1"
                                              ></label>
                                            </div>
                                          </div>
                                        </td>

                                        <td>
                                          <div class="radio-box">
                                            <input
                                              type="radio"
                                              id="star-3.5"
                                              name="rating"
                                              onClick={() => setrate(2)}
                                            />
                                            <div class="radio-box__state radio-box__state--primary">
                                              <label
                                                class="radio-box__label"
                                                for="star-3.5"
                                              ></label>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="radio-box">
                                            <input
                                              type="radio"
                                              id="star-4"
                                              name="rating"
                                              onClick={() => setrate(3)}
                                            />
                                            <div class="radio-box__state radio-box__state--primary">
                                              <label
                                                class="radio-box__label"
                                                for="star-4"
                                              ></label>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="radio-box">
                                            <input
                                              type="radio"
                                              id="star-4.5"
                                              name="rating"
                                              onClick={() => setrate(4)}
                                            />
                                            <div class="radio-box__state radio-box__state--primary">
                                              <label
                                                class="radio-box__label"
                                                for="star-4.5"
                                              ></label>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="radio-box">
                                            <input
                                              type="radio"
                                              id="star-5"
                                              name="rating"
                                              onClick={() => setrate(5)}
                                            />
                                            <div class="radio-box__state radio-box__state--primary">
                                              <label
                                                class="radio-box__label"
                                                for="star-5"
                                              ></label>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div class="rev-f2__group">
                                <div class="u-s-m-b-15">
                                  <label class="gl-label" for="reviewer-text">
                                    متن نظر *
                                  </label>
                                  <textarea
                                    class="text-area text-area--primary-style rounded-[15px]"
                                    id="reviewer-text"
                                    onChange={(e) => setContent(e.target.value)}
                                    value={content}
                                  ></textarea>
                                </div>
                              </div>
                              <div>
                                <button
                                  class="btn btn--e-brand-shadow"
                                  type="submit"
                                  onClick={handleSubmit}
                                >
                                  ارسال نظر
                                </button>
                              </div>
                            </form>
                          </div>
                        ) : (
                          <div class="u-s-m-b-30">
                            <form class="pd-tab__rev-f2">
                              <h2 class="u-s-m-b-25">
                                برای ثبت نظر باید ثبت نام کنید
                              </h2>
                            </form>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="u-s-p-b-90">
          <div class="section__intro u-s-m-b-46">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="section__text-wrap">
                    <h1 class="section__heading u-c-secondary u-s-m-b-12">
                      مشتریان از این محصولات هم دیدن کردند
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="section__content">
            <div class="container">
              <div className="row">
                {data2?.map((e) => (
                  <ProductCard data={e} home />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetails;
