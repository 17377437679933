import React from "react";
import { CDN_BASE_URL } from "../../config";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";

const EventCard = ({ item }) => {
  const renderer = ({ hours, minutes, seconds, completed, days }) => {
    if (completed) {
      return (
        <span className="px-3  flex flex-col justify-center items-center bg-white/80 border-2  p-2 rounded">
          زمان ثبت نام تمام شد
        </span>
      );
    } else {
      return (
        <div className=" p-3 flex  text-black gap-3 justify-center">
          <span className="px-3  flex flex-col justify-center items-center bg-white/80 border-2  p-2 rounded">
            <h3 className="m-0 text-[28px]">
              {hours < 10 ? "0" + hours : hours}
            </h3>
            <h6 className="text-[--brand]">ساعت</h6>
          </span>
          <span className="px-3  flex flex-col justify-center items-center bg-white/80 border-2  p-2 rounded">
            <h3 className="m-0 text-[28px]">{days < 10 ? "0" + days : days}</h3>
            <h6 className="text-[--brand]">روز</h6>
          </span>
        </div>
      );
    }
  };
  return (
    <Link  to={"/event/" + item._id} className="bg-white p-3 rounded h-fit">
      <img
        src={CDN_BASE_URL + item.img}
        className="w-full rounded"
        alt={item.name}
      />
      <h4 className="mt-2 text-center text-[22px] text-black font-bold">
        {item.name}
      </h4>
      <p className="mb-2 text-center">{item.category.name}</p>
      <Countdown renderer={renderer} date={new Date(item.date)} />
      <div className="flex justify-center mt-3">
        <Link
          to={"/event/" + item._id}
          className="btn btn--e-transparent-brand-b-2 p-2"
        >
          اطلاعات بیشتر
        </Link>
      </div>
    </Link>
  );
};

export default EventCard;
