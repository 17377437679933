import React, { useEffect, useRef, useState } from "react";
import Reveal from "../motion/Reveal";
import { httpReauest } from "../../utils/httprequest";
import Loading from "../common/Loading";
import Countdown from "react-countdown";
import CustomModal from "../common/Modal";
import SignupForm from "../common/SignupForm";
import { FaTimes } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";
import TopProducts from "./TopProducts";
import { Swiper, SwiperSlide } from "swiper/react";
import ProductCard from "../common/ProductCard";
import { Autoplay } from "swiper/modules";
import { CDN_BASE_URL } from "../../config";

const VITE_SITE_KEY = "6LchGzQqAAAAAACh11TFb0yF1BUb3nQAZMNZUPJD";
const SITE_SECRET = "6LchGzQqAAAAAM1RpZ-oQwac9Bas5x6L19KvPyHL";

const Dontmiss = () => {
  const [hour, sethours] = useState();
  const [show, setshow] = useState();
  const [data, setdata] = useState();
  const [products, setproducts] = useState();
  const [loading, setLoading] = useState();
  const [completed, setcompleted] = useState(false);
  const recaptcha = useRef(null);

  async function getData(params) {
    setLoading(true);
    await httpReauest("GET", "/admin/gift", {}, {}).then(({ data }) => {
      sethours(data?.data?.date);
      setdata(data?.data);
    });
    const newData = await httpReauest("GET", "/product", {}, {});

    setproducts(newData.data.data);
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  // useEffect(() => {
  //   console.log(recaptcha?.current);
  //
  // }, [recaptcha.current]);

  const renderer = ({ hours, minutes, seconds, completed, days }) => {
    if (completed) {
      setcompleted(true);
      return (
        <span className="px-3  flex flex-col justify-center items-center bg-white/80 border-2 border-[--brand] p-2 rounded mt-4">
          متاسفانه زمان دریافت هدیه تمام شد
        </span>
      );
    } else {
      return (
        <div className=" p-3 flex   text-black gap-3 ">
          <span className="px-3  flex flex-col justify-center items-center bg-white/80 border-2 border-[--brand] p-2 rounded">
            <h3 className="m-0 text-[28px]">
              {seconds < 10 ? "0" + seconds : seconds}
            </h3>
            <h6 className="text-[--brand]">ثانیه</h6>
          </span>
          <span className="px-3  flex flex-col justify-center items-center bg-white/80 border-2 border-[--brand] p-2 rounded">
            <h3 className="m-0 text-[28px]">
              {minutes < 10 ? "0" + minutes : minutes}
            </h3>
            <h6 className="text-[--brand]">دقیقه</h6>
          </span>
          <span className="px-3  flex flex-col justify-center items-center bg-white/80 border-2 border-[--brand] p-2 rounded">
            <h3 className="m-0 text-[28px]">
              {hours < 10 ? "0" + hours : hours}
            </h3>
            <h6 className="text-[--brand]">ساعت</h6>
          </span>
          <span className="px-3  flex flex-col justify-center items-center bg-white/80 border-2 border-[--brand] p-2 rounded">
            <h3 className="m-0 text-[28px]">{days < 10 ? "0" + days : days}</h3>
            <h6 className="text-[--brand]">روز</h6>
          </span>
        </div>
      );
    }
  };

  return (
    <div class="banner-bg">
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          background: "#00000070",
        }}
      ></div>
      <CustomModal show={show} onHide={() => setshow(false)} fit>
        <div className="">
          <FaTimes
            className="mb-2 cursor-pointer"
            size={22}
            onClick={() => setshow(false)}
          />
          <form className="flex flex-col items-center justify-center gap-2 w-full p-0 m-0">
            <h3 className="text-black">ریکپچا را کامل کنید</h3>
            <ReCAPTCHA
              onChange={(e) => {
                if (e !== "") {
                  window.location.assign(CDN_BASE_URL + data?.file);
                }
              }}
              sitekey={VITE_SITE_KEY}
              ref={recaptcha}
            />
            <h3 className="text-black">سایر محصولات</h3>
            <Swiper
              modules={[Autoplay]}
              autoplay={{ delay: 3000 }}
              className="w-[70%]"
            >
              {products?.map((item) => (
                <SwiperSlide className="flex justify-center p-0 m-0">
                  <ProductCard full data={item} />
                </SwiperSlide>
              ))}
            </Swiper>
          </form>
        </div>
      </CustomModal>

      <div class="section__content">
        <div class="container">
          {loading ? (
            <Loading />
          ) : (
            <Reveal head>
              <div class="row">
                <div class="col-lg-12">
                  <div class="banner-bg__wrap">
                    <div class="text-[26px] lg:text-[42px]">
                      <span class="u-c-white">هدیه خود را دریافت کنید</span>
                    </div>
                    <div className="flex justify-center">
                      {hour && (
                        <Countdown date={new Date(hour)} renderer={renderer} />
                      )}
                    </div>
                    {completed ? (
                      <></>
                    ) : (
                      <>
                        <div class="banner-bg__text-2">
                          <span class="u-c-brand">این فرصت رو از دست نده!</span>
                        </div>

                        <span class="banner-bg__text-block banner-bg__text-3 u-c-white">
                          {data?.name}
                        </span>

                        <button
                          class="banner-bg__shop-now btn--e-secondary"
                          disabled={completed}
                          onClick={() => setshow(true)}
                        >
                          دریافت
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Reveal>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dontmiss;
