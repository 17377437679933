import React, { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import { Link } from "react-router-dom";
import Reveal from "../components/motion/Reveal";
import Reviews from "../components/home/Reviews";
import { httpReauest } from "../utils/httprequest";
import EventCard from "../components/common/EventCard";
import { FaCircle } from "react-icons/fa";
import { FaCircleCheck } from "react-icons/fa6";

const EventPage = () => {
  const [data, setData] = useState([]);
  const [category, setcategory] = useState([]);
  const [selected, setselected] = useState("");

  async function getData() {
    await httpReauest("GET", "/event", {}, {}).then((res) =>
      setData(res.data.data)
    );
    await httpReauest("GET", "/event/category", {}, {}).then((res) =>
      setcategory(res.data.data)
    );
  }

  async function getFilter() {
    await httpReauest("GET", "/event?category=" + selected, {}, {}).then(
      (res) => setData(res.data.data)
    );
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (selected) {
      getFilter();
    }
  }, [selected]);

  return (
    <div>
      <Header />
      <div class="app-content bg-[#eee]">
        <div class="u-s-p-y-10">
          <div class="section__intro u-s-m-b-20">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="section__text-wrap">
                    <Reveal head>
                      <h1 class="section__heading u-c-secondary">روادید</h1>
                    </Reveal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="u-s-p-y-10">
          <div class="section__intro u-s-m-b-15">
            <div class="container">
              <span className="bg-white rounded-full px-4 py-2 flex gap-3 lg:gap-4 gl-scroll overflow-x-scroll lg:overflow-hidden items-center">
                <span
                  onClick={() => setselected("")}
                  className="flex gap-2 items-center cursor-pointer"
                >
                  همه
                  {selected == "" ? (
                    <FaCircleCheck color="green" />
                  ) : (
                    <FaCircle color="#eee" />
                  )}
                </span>
                {category?.map((item) => (
                  <span
                    onClick={() => setselected(item._id)}
                    className="flex gap-2 items-center cursor-pointer"
                  >
                    {item.name}{" "}
                    {selected == item._id ? (
                      <FaCircleCheck color="green" />
                    ) : (
                      <FaCircle color="#eee" />
                    )}
                  </span>
                ))}
              </span>
            </div>
          </div>
        </div>
        <div class="u-s-p-y-10">
          <div class="section__intro u-s-m-b-46">
            <div class="container">
              <div className="grid sm:grid-cols-2  md:grid-cols-3 gap-3">
                {data?.map((item) => (
                  <EventCard item={item} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EventPage;
