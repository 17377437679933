import React, { useContext, useState } from "react";
import Reveal from "../motion/Reveal";
import { Link, useParams } from "react-router-dom";
import { FilterContext } from "../../App";

const Filter = ({ categorys, setselected, show, setshow }) => {
  const [minprice, setMinprice] = useState();
  const [maxinprice, setmaxinprice] = useState();
  const { filter_category, setfilter_category } = useContext(FilterContext);
  const { category } = useParams();

  

  return (
    <div class="col-lg-3 col-md-12">
      <div class="shop-w-master">
        <Reveal>
          <h1 class="shop-w-master__heading u-s-m-b-30">
            <i class="fa-solid fa-filter u-s-m-l-8"></i>

            <span>فیلتر </span>
          </h1>
        </Reveal>

        <div
          class={`${
            !show && "left-[-100%]"
          } fixed left-0 top-0 h-full w-[250px] bg-white z-10 duration-500`}
        >
          <div class="w-full border-b p-1">
            <span onClick={() => setshow(false)} class="ah-close">
              ✕
            </span>
          </div>
          <div class="u-s-m-b-30 bg-white rounded-[15px]">
            <div class="">
              <div class="shop-w__intro-wrap">
                <h1 class="shop-w__h">دسته بندی</h1>
              </div>
              <ul class="px-4 text-xs">
                {categorys?.map((e) => {
                  console.log(category == e.url)
                  return (
                    <li class="flex justify-between items-center transition text-sm md:text-15px bg-fill-base px-0 border-t border-border-base first:border-t-0 mx-[3px] bg-transparent">
                      <Link
                        to={"/products/" + e.url}
                        class="flex items-center w-full ltr:text-left rtl:text-right cursor-pointer group py-3 xl:py-3.5 2xl:py-2.5 3xl:py-3"
                      >
                        <span class="text-brand-dark capitalize py-0.5">
                          {e.name}
                        </span>
                        <span class="w-[22px] text-[--brand] h-[22px] text-13px flex items-center justify-center border-2 border-border-four rounded-full ltr:ml-auto rtl:mr-auto transition duration-500 ease-in-out group-hover:border-[--brand] text-brand-light border-[--brand]">
                          {category == e.url ? (
                            <Link to="/products" className="fa-solid fa-check"></Link>
                          ) : null}
                        </span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>

        <Reveal>
          <div class="shop-w-master__sidebar sidebar--bg-snow rounded-[15px] bg-[#eee] ">
            <div class="u-s-m-b-30 bg-white rounded-[15px]">
              <div class="">
                <div class="shop-w__intro-wrap ">
                  <h1 class="shop-w__h">دسته بندی</h1>
                </div>
                <ul class="px-4 text-xs">
                  {categorys?.map((e) => {
                    return (
                      <li class="flex justify-between items-center transition text-sm md:text-15px bg-fill-base px-0 border-t border-border-base first:border-t-0 mx-[3px] bg-transparent">
                        <Link
                          to={"/products/" + e.url}
                          class="flex items-center w-full ltr:text-left rtl:text-right cursor-pointer group py-3 xl:py-3.5 2xl:py-2.5 3xl:py-3"
                        >
                          <span class="text-brand-dark capitalize py-0.5">
                            {e.name}
                          </span>
                          <span class="w-[22px] text-[--brand] h-[22px]  flex items-center justify-center border rounded-full ltr:ml-auto rtl:mr-auto transition duration-500 ease-in-out group-hover:border-[--brand] text-brand-light border-[--brand]">
                            {category == e.url ? (
                              <Link to="/products" className="fa-solid fa-check"></Link>
                            ) : null}
                          </span>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </Reveal>
      </div>
    </div>
  );
};

export default Filter;
