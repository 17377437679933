import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import ProductSlider from "../components/common/ProductSlider";
import Reveal from "../components/motion/Reveal";
import ProductCard from "../components/common/ProductCard";
import { httpReauest } from "../utils/httprequest";
import { Link, useParams } from "react-router-dom";
import RateToStar from "../utils/rateToStart";
import { toast } from "react-toastify";
import { BASE_URL, CDN_BASE_URL } from "../config";
import Countdown from "react-countdown";
import moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FaPlus } from "react-icons/fa";

const EventDetails = () => {
  const [reviewShow, setReviewShow] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [content, setContent] = useState();
  const [user, setuser] = useState();
  const [rate, setrate] = useState();
  const [error, seterror] = useState();
  const [loading, setloading] = useState();
  const { id } = useParams();
  const [img, setImg] = useState();
  const [previewImg, setPreviewImg] = useState();

  const [data, setData] = useState();
  const [data2, setData2] = useState();
  useEffect(() => {
    getData();
  }, [id]);

  async function getData() {
    const newData = await httpReauest("GET", "/event/" + id, {}, {});

    setData(newData.data.data);

    const newData2 = await httpReauest("GET", "/event", {}, {});

    setData2(newData2.data.data);

    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      setIsLogin(true);
      getUser(user._id, user.token);
    }
  }

  const renderer = ({ hours, minutes, seconds, completed, days }) => {
    if (completed) {
      return (
        <span className="px-3  flex flex-col justify-center items-center bg-white/80 border-2  p-2 rounded">
          زمان ثبت نام تمام شد
        </span>
      );
    } else {
      return (
        <div className=" flex  text-black gap-3 justify-center">
          <span className="px-3  flex flex-col justify-center items-center bg-white/80 border-2  p-2 rounded">
            <h3 className="m-0 text-[28px]">
              {seconds < 10 ? "0" + seconds : seconds}
            </h3>
            <h6 className="text-[--brand]">ثانیه</h6>
          </span>
          <span className="px-3  flex flex-col justify-center items-center bg-white/80 border-2  p-2 rounded">
            <h3 className="m-0 text-[28px]">
              {minutes < 10 ? "0" + minutes : minutes}
            </h3>
            <h6 className="text-[--brand]">دقیقه</h6>
          </span>
          <span className="px-3  flex flex-col justify-center items-center bg-white/80 border-2  p-2 rounded">
            <h3 className="m-0 text-[28px]">
              {hours < 10 ? "0" + hours : hours}
            </h3>
            <h6 className="text-[--brand]">ساعت</h6>
          </span>
          <span className="px-3  flex flex-col justify-center items-center bg-white/80 border-2  p-2 rounded">
            <h3 className="m-0 text-[28px]">{days < 10 ? "0" + days : days}</h3>
            <h6 className="text-[--brand]">روز</h6>
          </span>
        </div>
      );
    }
  };

  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState();
  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
      phone: Yup.string().required("الزامی است"),
    });
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      nationalCode: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      if (!img) {
        return toast.error("عکس فیش را بارگزاری کنید");
      }
      setloading(true);
      const formdata = new FormData();
      formdata.append("name", values.name);
      formdata.append("phone", values.phone);
      formdata.append("nationalCode", values.nationalCode);
      formdata.append("event", id);
      if (img) {
        formdata.append("payment", img, img?.name);
      }

      await fetch(BASE_URL + "/event/sign", {
        method: "POST",
        body: formdata,
        headers: { "x-access-token": user.token },
      }).then((dat) => {
        if (dat?.status == 201) {
          toast.success(
            "اطلاعات شما ثبت شد برای اطلاعات بیشتز همکاران ما با ما شما ارتباط برقرار میکنند"
          );
          formik.resetForm();
          setloading(false);
        }
      });
    },
  });

  async function getUser(_id, token) {
    await httpReauest(
      "GET",
      "/user/" + _id,
      {},
      { "x-access-token": token }
    ).then((res) => {
      setuser(res.data.data);
      formik.setFieldValue(
        "name",
        `${res.data.data.f_name} ${res.data.data.l_name}`
      );
      formik.setFieldValue("phone", res.data.data.phone);
    });
  }

  console.log(formik.values);

  // function isValidIranianNationalCode(input) {
  //   if (
  //     input ==
  //     ("1111111111" ||
  //       "2222222222" ||
  //       "3333333333" ||
  //       "4444444444" ||
  //       "5555555555" ||
  //       "6666666666" ||
  //       "7777777777" ||
  //       "8888888888" ||
  //       "9999999999" ||
  //       "0000000000")
  //   ) {
  //     formik.setFieldError("nationalCode", "کد ملی صحیح نمی باشد");
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  function nationalCodeHandle(e) {
    const reg = /^(\d)\1*$/;

    if (e.length !== 10) {
      seterror("معتبر نیست");
    } else {
      if (
        e ==
        ("1111111111" ||
          "2222222222" ||
          "3333333333" ||
          "4444444444" ||
          "5555555555" ||
          "6666666666" ||
          "7777777777" ||
          "8888888888" ||
          "9999999999" ||
          "0000000000")
      ) {
        return seterror("معتبر نیست");
      }
      seterror(null);
    }
  }

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  return (
    <div>
      <Header />
      <div style={{ background: "#eee" }} class="app-content px-4 pb-8">
        <div class="u-s-p-t-60">
          <div class="container bg-white rounded-[25px] p-4">
            <div class="row items-center">
              <div class="col-lg-5">
                <div class="pd mb-6 lg:mb-0">
                  <img
                    src={CDN_BASE_URL + data?.img}
                    className="w-full rounded"
                    alt={data?.name}
                  />
                </div>
              </div>
              <div class="col-lg-7">
                <div class="pd-detail">
                  <Reveal width={"fit-content"}>
                    <div>
                      <span class="pd-detail__name">{data?.name}</span>
                    </div>
                  </Reveal>

                  <div class="u-s-m-b-10">
                    <Reveal width={"fit-content"}>
                      <span class="pd-detail__preview-desc">{data?.desc}</span>
                    </Reveal>
                  </div>
                  <div class="u-s-m-b-10">
                    <Reveal width={"fit-content"}>
                      <span class="pd-detail__preview-desc">
                        تاریخ برگزاری :{" "}
                        {moment(data?.date).format("YYYY/MM/DD")}
                      </span>
                    </Reveal>
                  </div>
                  <div class="u-s-m-b-10">
                    <Reveal width={"fit-content"}>
                      <span class="pd-detail__preview-desc">
                        هزینه ثبت نام: {data?.price} تومان
                      </span>
                    </Reveal>
                  </div>
                  <div class="u-s-m-b-25">
                    <Reveal width={"fit-content"}>
                      <span class="pd-detail__preview-desc">
                        محل برگزاری : {data?.location}
                      </span>
                    </Reveal>
                  </div>
                  {data?.voice && (
                    <div class="u-s-m-b-25">
                      <Reveal width={"fit-content"}>
                        <audio
                          src={CDN_BASE_URL + data?.voice}
                          className="w-full"
                        />
                      </Reveal>
                    </div>
                  )}
                  <Countdown renderer={renderer} date={new Date(data?.date)} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="u-s-m-y-60">
          <div class="container bg-white rounded-[25px] p-4">
            <div class="row">
              <div class="col-lg-12">
                <div class="pd-tab">
                  <div class="u-s-m-b-30">
                    <ul class="nav pd-tab__list">
                      <li class="nav-item">
                        <a
                          class={`nav-link ${!reviewShow && "active"}`}
                          data-toggle="tab"
                          onClick={() => setReviewShow(false)}
                        >
                          ثبت نام
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class={`nav-link ${reviewShow && "active"}`}
                          id="view-review"
                          data-toggle="tab"
                          onClick={() => setReviewShow(true)}
                        >
                          گالری
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div class="tab-content">
                    <div
                      class={`tab-pane ${!reviewShow && "fade show active"}`}
                      id="pd-desc"
                    >
                      <form
                        onSubmit={formik.handleSubmit}
                        className="w-full flex flex-wrap px-4"
                      >
                        <span className="col-lg-4 col-md-6 p-3 col-12">
                          <label>نام و نام خانوادگی *</label>
                          <br />
                          <input
                            id="name"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="w-full mt-2 border p-2 rounded"
                          />
                          {formik.errors.name && formik.touched.name && (
                            <small className="text-red-500">
                              {formik.errors.name}
                            </small>
                          )}
                        </span>

                        <span className="col-lg-4 col-md-6 p-3 col-12">
                          <label>کد ملی *</label>
                          <br />
                          <input
                            id="nationalCode"
                            name="nationalCode"
                            value={formik.values.nationalCode}
                            onChange={(e) => {
                              formik.handleChange(e);
                              nationalCodeHandle(e.target.value);
                            }}
                            onBlur={formik.handleBlur}
                            className="w-full mt-2 border p-2 rounded"
                          />
                          {error && (
                            <small className="text-red-500">{error}</small>
                          )}
                        </span>

                        <span className="col-lg-4 col-md-6 p-3 col-12">
                          <label>شماره همراه *</label>
                          <br />
                          <input
                            id="phone"
                            name="phone"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="w-full mt-2 border p-2 rounded"
                          />
                          {formik.errors.phone && formik.touched.phone && (
                            <small className="text-red-500">
                              {formik.errors.phone}
                            </small>
                          )}
                        </span>
                        <div className=" flex justify-center flex-wrap mx-auto">
                          <label>تصویر پرداختی*</label>
                          <label
                            htmlFor="img"
                            className="relative w-full mt-2 border-dashed border-2  rounded-[10px] p-3 h-[160px] cursor-pointer border-[#444] "
                          >
                            {previewImg ? (
                              <img
                                src={previewImg}
                                className="w-full h-full object-contain"
                                alt=""
                              />
                            ) : (
                              <FaPlus
                                className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                                size={30}
                              />
                            )}
                          </label>
                          <input
                            dir="ltr"
                            disabled
                            class="shadow border rounded p-2 mt-3 w-full"
                            value="5022-2910-1917-7474"
                            data-listener-added_1639567b="true"
                          />
                          <span className="text-right w-full">
                            مهیار بابازاده
                          </span>

                          <input
                            onChange={(e) => {
                              handleImg(e);
                            }}
                            type="file"
                            className="hidden"
                            id="img"
                            accept="image/png, image/jpg, image/jpeg"
                          />
                        </div>

                        <span className="col-12 p-3 flex justify-center">
                          {loading ? (
                            <div
                              id="spinner"
                              className="show bg-white d-flex align-items-center justify-content-center"
                            >
                              <div
                                className="spinner-border text-primary"
                                style={{ width: "2rem", height: "2rem" }}
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          ) : (
                            <button
                              type="submit"
                              className="shop-now-link btn--e-brand"
                            >
                              تایید
                            </button>
                          )}
                        </span>
                        {/* <span className="col-12 p-3">
                          {success && (
                            <Alert
                              type={"success"}
                              contnet={
                                "اطلاعات شما با موفقیت ثبت شد و مورد بررسی قرار میگیرد"
                              }
                            />
                          )}
                          {errors && <Alert type={"error"} contnet={errors} />}
                        </span> */}
                      </form>
                    </div>
                    <div class={`tab-pane ${reviewShow && "fade show active"}`}>
                      <div className="pt-6">
                        {data?.images?.length ? (
                          <ProductSlider data={data?.images} />
                        ) : (
                          "تصویری وجود ندارد"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EventDetails;
