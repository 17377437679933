import React from "react";
import Reveal from "../motion/Reveal";
import { Link } from "react-router-dom";
import { SrviceInfo } from "../../pages/ServiceDetails";

const Footer = () => {
  return (
    <footer>
      <div class="outer-footer">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="outer-footer__content u-s-m-b-40">
                <Reveal head>
                  <span class="outer-footer__content-title">ارتباط با ما</span>
                  <div class="outer-footer__text-wrap">
                    <i class="fa-solid fa-home"></i>

                    <span>ادرس دفتر کاری</span>
                  </div>
                  <div class="outer-footer__text-wrap">
                    <i class="fa-solid fa-phone-volume"></i>

                    <span>0999-999-9999</span>
                  </div>
                  <div class="outer-footer__text-wrap">
                    <i class="fa-regular fa-envelope"></i>

                    <span>contact@domain.com</span>
                  </div>
                  <div class="outer-footer__social">
                    <ul>
                      <li>
                        <a class="s-fb--color-hover" href="#">
                          <i class="fa-brands fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a class="s-tw--color-hover" href="#">
                          <i class="fa-brands fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a class="s-youtube--color-hover" href="#">
                          <i class="fa-brands fa-youtube"></i>
                        </a>
                      </li>
                      <li>
                        <a class="s-insta--color-hover" href="#">
                          <i class="fa-brands fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a class="s-gplus--color-hover" href="#">
                          <i class="fa-brands fa-google-plus-g"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </Reveal>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <div class="outer-footer__content u-s-m-b-40">
                    <Reveal head>
                      <span class="outer-footer__content-title">خدمات</span>
                      <div class="outer-footer__list-wrap">
                        <ul>
                          {SrviceInfo.map((e) => {
                            return (
                              <li>
                                <Link to={"/service/" + e.id}>{e.title}</Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </Reveal>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="outer-footer__content u-s-m-b-40">
                    <div class="outer-footer__list-wrap">
                      <Reveal head>
                        <span class="outer-footer__content-title">روادید</span>
                        <ul>
                          <li>
                            <Link to="/event">سمینار</Link>
                          </li>
                          <li>
                            <Link to="/event">تور</Link>
                          </li>
                          <li>
                            <Link to="/event">کارگاه آموزشی</Link>
                          </li>
                          <li>
                            <Link to="/event">بوت کمپ</Link>
                          </li>
                        </ul>
                      </Reveal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <div class="outer-footer__content u-s-m-b-40">
                    <Reveal head>
                      <span class="outer-footer__content-title">محصولات</span>
                      <div class="outer-footer__list-wrap">
                        <ul>
                          <li>
                            <Link to="/products/courses">دوره های آموزشی</Link>
                          </li>
                          <li>
                            <Link to="/products/pdf-courses">
                              دوره های آموزشی PDF
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Reveal>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="outer-footer__content u-s-m-b-40">
                    <div class="outer-footer__list-wrap">
                      <Reveal head>
                        <span class="outer-footer__content-title">
                          سایر صفحات
                        </span>
                        <ul>
                          <li>
                            <Link to="/about">درباره ما</Link>
                          </li>
                          <li>
                            <Link to="/signup">ثبت نام</Link>
                          </li>
                          <li>
                            <Link to="/signin">ورود</Link>
                          </li>
                        </ul>
                      </Reveal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lower-footer">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="lower-footer__content">
                <div class="lower-footer__copyright">
                  <span>© تمامی حقوق محفوظ است</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
